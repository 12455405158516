const initialState = {
  saleAnalyticByPlan: {},
  saleAnalyticByMake: {},
  saleAnalyticByModel: {},
  claimAnalyticByPlan: {},
  claimAnalyticByMake: {},
  claimAnalyticByModel: {},

  saleAnalyticByPlanHoverData: {},
  saleAnalyticByMakeHoverData: {},
  saleAnalyticByModelHoverData: {},
  claimAnalyticByPlanHoverData: {},
  claimAnalyticByMakeHoverData: {},
  claimAnalyticByModelHoverData: {},
  // type of claim

  typeOfClaimByPlan: {},
  typeOfClaimByMake: {},
  typeOfClaimByModel: {},
  typeOfClaimByPlanHoverData: {},
  typeOfClaimByMakeHoverData: {},
  typeOfClaimByModelHoverData: {},

  // // type of claim make modal

  typeOfClaimMakeByMake: {},
  typeOfClaimMakeByModel: {},
  typeOfClaimMakeByMakeHoverData: {},
  typeOfClaimMakeByModelHoverData: {},
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SALE_ANALYTICS_PLAN":
      return {
        ...state,
        saleAnalyticByPlan: action.data,
        saleAnalyticByPlanHoverData: action.hoverData,
      };
    case "GET_SALE_ANALYTICS_MAKE":
      return {
        ...state,
        saleAnalyticByMake: action.data,
        saleAnalyticByMakeHoverData: action.hoverData,
      };
    case "GET_SALE_ANALYTICS_MODEL":
      return {
        ...state,
        saleAnalyticByModel: action.data,
        saleAnalyticByModelHoverData: action.hoverData,
      };
    case "GET_CLAIM_ANALYTICS_PLAN":
      return {
        ...state,
        claimAnalyticByPlan: action.data,
        claimAnalyticByPlanHoverData: action.hoverData,
      };
    case "GET_CLAIM_ANALYTICS_MAKE":
      return {
        ...state,
        claimAnalyticByMake: action.data,
        claimAnalyticByMakeHoverData: action.hoverData,
      };
    case "GET_CLAIM_ANALYTICS_MODEL":
      return {
        ...state,
        claimAnalyticByModel: action.data,
        claimAnalyticByModelHoverData: action.hoverData,
      };
    case "GET_SALE_ANALYTICS_PLAN_HOVER":
      return {
        ...state,
        saleAnalyticByPlanHoverData: {
          ...state.saleAnalyticByPlanHoverData,
          [action.pos]: action.data,
        },
      };

    case "GET_SALE_ANALYTICS_MAKE_HOVER":
      return {
        ...state,

        saleAnalyticByMakeHoverData: {
          ...state.saleAnalyticByMakeHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_SALE_ANALYTICS_MODEL_HOVER":
      return {
        ...state,
        saleAnalyticByModelHoverData: {
          ...state.saleAnalyticByModelHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_CLAIM_ANALYTICS_PLAN_HOVER":
      return {
        ...state,
        claimAnalyticByPlanHoverData: {
          ...state.claimAnalyticByPlanHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_CLAIM_ANALYTICS_MAKE_HOVER":
      return {
        ...state,
        claimAnalyticByMakeHoverData: {
          ...state.claimAnalyticByMakeHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_CLAIM_ANALYTICS_MODEL_HOVER":
      return {
        ...state,
        claimAnalyticByModelHoverData: {
          ...state.claimAnalyticByModelHoverData,
          [action.pos]: action.data,
        },
      };

    // type of claim
    case "GET_TYPEOFCLAIM_ANALYTICS_PLAN":
      return {
        ...state,
        typeOfClaimByPlan: action.data,
        typeOfClaimByPlanHoverData: action.hoverData,
      };
    case "GET_TYPEOFCLAIM_ANALYTICS_MAKE":
      return {
        ...state,
        typeOfClaimByMake: action.data,
        typeOfClaimByMakeHoverData: action.hoverData,
      };
    case "GET_TYPEOFCLAIM_ANALYTICS_MODEL":
      return {
        ...state,
        typeOfClaimByModel: action.data,
        typeOfClaimByModelHoverData: action.hoverData,
      };
    case "GET_TYPEOFCLAIM_ANALYTICS_PLAN_HOVER":
      // alert("ok");
      return {
        ...state,
        typeOfClaimByPlanHoverData: {
          ...state.typeOfClaimByPlanHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_TYPEOFCLAIM_ANALYTICS_MAKE_HOVER":
      return {
        ...state,
        typeOfClaimByMakeHoverData: {
          ...state.typeOfClaimByMakeHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_TYPEOFCLAIM_ANALYTICS_MODEL_HOVER":
      return {
        ...state,
        typeOfClaimByModelHoverData: {
          ...state.typeOfClaimByModelHoverData,
          [action.pos]: action.data,
        },
      };

    // Type Of Claim Make Modal

    case "GET_TYPEOFCLAIM_MAKE_ANALYTICS_MAKE":
      return {
        ...state,
        typeOfClaimMakeByMake: action.data,
        typeOfClaimMakeByMakeHoverData: action.hoverData,
      };
    case "GET_TYPEOFCLAIM_MAKE_ANALYTICS_MODEL":
      return {
        ...state,
        typeOfClaimMakeByModel: action.data,
        typeOfClaimMakeByModelHoverData: action.hoverData,
      };
    case "GET_TYPEOFCLAIM_MAKE_ANALYTICS_MAKE_HOVER":
      return {
        ...state,
        typeOfClaimMakeByMakeHoverData: {
          ...state.typeOfClaimMakeByMakeHoverData,
          [action.pos]: action.data,
        },
      };
    case "GET_TYPEOFCLAIM_MAKE_ANALYTICS_MODEL_HOVER":
      return {
        ...state,
        typeOfClaimMakeByModelHoverData: {
          ...state.typeOfClaimMakeByModelHoverData,
          [action.pos]: action.data,
        },
      };

    default: {
      return state;
    }
  }
};

export default analyticsReducer;
