const initialState = {
    list: "",
  },
  messagesReducers = (state = initialState, action) => {
    switch (action.type) {
      case "GET_MESSAGES":
        return { ...state, list: action.data };
      default:
        return state;
    }
  };

export default messagesReducers;
