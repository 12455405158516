import cases from "./cases";

const initialState = {
  agreements: { list: "", details: "" },
  agreementsDraft: { list: "", details: "" },
  claims: { list: "", details: "" },
  plans: { list: "", details: "" },
  dealers: { list: "", details: "" },
  canellations: { list: "", details: "" },
  invoice: { list: "", details: "" },
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case cases.GET_AGREEMENT_LIST:
      return {
        ...state,
        agreements: { ...state.agreements, list: action.data },
      };
    case cases.GET_AGREEMENT_DRAFT_LIST:
      return {
        ...state,
        agreementsDraft: { ...state.agreementsDraft, list: action.data },
      };
    case cases.GET_AGREEMENT_DETAIL:
      return {
        ...state,
        agreements: { ...state.agreements, details: action.data },
      };
    case cases.GET_CLAIM_LIST:
      return {
        ...state,
        claims: { ...state.claims, list: action.data },
      };
    case cases.GET_CLAIM_DETAIL:
      return {
        ...state,
        claims: { ...state.claims, details: action.data },
      };
    case cases.GET_PLAN_LIST:
      return {
        ...state,
        plans: { ...state.plans, list: action.data },
      };
    case cases.GET_PLAN_DETAIL:
      return {
        ...state,
        plans: { ...state.plans, details: action.data },
      };
    case cases.GET_DEALER_LIST:
      return {
        ...state,
        dealers: { ...state.dealers, list: action.data },
      };
    case cases.GET_DEALER_DETAIL:
      return {
        ...state,
        dealers: { ...state.dealers, details: action.data },
      };
    case cases.GET_CANCELLATIONS_LIST:
      return {
        ...state,
        canellations: { ...state.canellations, list: action.data },
      };
    case cases.GET_CANCELLATIONS_DETAIL:
      return {
        ...state,
        canellations: { ...state.canellations, details: action.data },
      };
    case cases.GET_INVOICE_LIST:
      return {
        ...state,
        invoice: { ...state.invoice, list: action.data },
      };
    case cases.GET_INVOICE_DETAIL:
      return {
        ...state,
        invoice: { ...state.invoice, details: action.data },
      };
    default:
      return {
        ...state,
      };
  }
};

export default appReducer;
