const inititalState = {
    dealer_members: "",
    sales_representative_members: "",
    agency_members: "",
    administrator_members: "",
    repair_facility_members: "",
    corporate_members: "",
  },
  membersReducers = (state = inititalState, actions) => {
    switch (actions.type) {
      case "GET_DEALER_MEMBERS":
        return { ...state, dealer_members: actions.data };
      case "GET_AGENCY_MEMBERS":
        return { ...state, agency_members: actions.data };
      case "GET_SALES_REPRESENTATIVE_MEMBERS":
        return { ...state, sales_representative_members: actions.data };
      case "GET_ADMINISTRATOR_MEMBERS":
        return { ...state, administrator_members: actions.data };
      case "GET_REPAIR_FACILITY_MEMBERS":
        return { ...state, repair_facility_members: actions.data };
      case "GET_CORPORATE_MEMBERS":
        return { ...state, corporate_members: actions.data };
      default:
        return state;
    }
  };

export default membersReducers;
