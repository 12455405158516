import React, { Fragment } from "react";
import { toast } from "react-toastify";
import Avatar from "@components/avatar";

export default function ToastComponentFun(type, Icon, title, des) {
  return toast[type](
    <Fragment>
      <div className="toastify-header pb-0">
        <div className="title-wrapper">
          <div className="title-av">
            <Avatar
              size="sm"
              className="title-av"
              color={type === "error" ? "danger" : type}
              icon={<Icon />}
            />
          </div>
          <div>
            <h6 className="toast-title first_transform">{title}</h6>
            <p className="toast-des">{des}</p>
          </div>
        </div>
      </div>
    </Fragment>,
    {
      //   transition: Slide,
      autoClose: 5000,
      hideProgressBar: true,
      closeButton: false,
    }
  );
}
export function ToastComponent({ title, icon, color }) {
  return (
    <Fragment>
      <div className="toastify-header pb-0">
        <div className="title-wrapper">
          <Avatar size="sm" color={color} icon={icon} />
          <h6 className="toast-title paragraphic">{title}</h6>
        </div>
      </div>
    </Fragment>
  );
}

export const labelContainer = (arg) => {
  if (arg.includes("_")) {
    const str = arg.replace(/_/g, " ");
    return str;
  } else {
    return arg;
  }
};
