// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import membersReducers from "./../../views/members/@store/reducers/index";
import messagesReducers from "./../../views/text-and-messaging/@store/reducers";
import dashboardReducers from "../../views/dashboard/@store/reducers";
import agreementsReducers from "../../views/agreements/@store/reducers";
import analyticsReducer from "../../views/analytics/@store/reducers";
import appReducer from "../reducers";
import agreementTransferReducers from "../../views/agreements/agreement-transfer/@store/reducers/index";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  membersReducers,
  messagesReducers,
  dashboardReducers,
  agreementsReducers,
  analyticsReducer,
  state: appReducer,
  agreementTransferReducers,
});

export default rootReducer;
