const initialState = {
    list: "",
    details: "",
  },
  agreementsReducers = (state = initialState, action) => {
    switch (action.type) {
      // case "GET_AGREEMENT_LIST":
      //   return { ...state, list: action.data };
      case "GET_AGREEMENT_DETAILS":
        return { ...state, details: action.data };
      default:
        return state;
    }
  };

export default agreementsReducers;
