const cases = {
  GET_AGREEMENT_LIST: "GET_AGREEMENT_LIST",
  GET_AGREEMENT_DETAIL: "GET_AGREEMENT_DETAIL",
  GET_CLAIM_LIST: "GET_CLAIM_LIST",
  GET_CLAIM_DETAIL: "GET_CLAIM_DETAIL",
  GET_AGREEMENT_DRAFT_LIST: "GET_AGREEMENT_DRAFT_LIST",
  GET_PLAN_LIST: "GET_PLAN_LIST",
  GET_PLAN_DETAIL: "GET_PLAN_DETAIL",
  GET_DEALER_LIST: "GET_DEALER_LIST",
  GET_DEALER_DETAIL: "GET_DEALER_DETAIL",
  GET_CANCELLATIONS_LIST: "GET_CANCELLATIONS_LIST",
  GET_CANCELLATIONS_DETAIL: "GET_CANCELLATIONS_DETAIL",
  GET_INVOICE_LIST: "GET_INVOICE_LIST",
  GET_INVOICE_DETAIL: "GET_INVOICE_DETAIL",
};

export default cases;
