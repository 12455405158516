// ** Logo
import logo from "@src/assets/images/logo/Dealeradmin.png";
import { Col, Row } from "reactstrap";
import { customDomain } from "../../../@constants/authentications";

const SpinnerComponent = () => {
  return (
    <Row className="d-flex align-items-center m-0" style={{ height: "100vh" }}>
      <Col md="3"></Col>
      <Col md="6" className="text-center">
        <img
          src={customDomain ? customDomain.site_logo : logo}
          alt=""
          className="img-fluid"
        />
      </Col>
      <Col md="3"></Col>
    </Row>
  );
};

export default SpinnerComponent;
